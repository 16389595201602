newsCard {
    width: 100% !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.newsCard  div[class="ant-card-cover"] {
    height: 200px;
}
.newsCard  div[class="ant-card-cover"] img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}