.div {
    background: transparent;
    height: 100vh;
    text-align: center;
}

.spin {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
